<template>
    <v-row no-gutters @click="onClickOutside" style="height: 100%;">
        <v-col cols="12">
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10">
                <v-card class="pa-0">
                    <v-toolbar short flat :style="cardTitleBarStyle">
                        <v-toolbar-title :style="cardTitleBarTextStyle">Edit nameserver group</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-menu offset-y left open-on-click open-on-hover close-delay="100">
                            <template v-slot:activator="{ on }">
                                <v-btn icon dark v-on="on">
                                    <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                                </v-btn>
                            </template>
                            <v-list class="ma-0 pa-0">
                                <v-list-item-group>
                                <v-list-item @click="redirectToDeleteNameserverGroup">
                                    <v-list-item-icon>
                                        <font-awesome-icon :icon="['fas', 'trash']" color="red" fixed-width/>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Delete...</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-menu>
                    </v-toolbar>
                    <v-card-text v-if="nameserverGroup">
                        <EditableText :value="nameserverGroup.label" @input="saveNameserverGroupLabel" dense/>
                    </v-card-text>
                    <v-card-text v-if="nameserverList.length === 0">
                        <p>Use the green <font-awesome-icon :icon="['fas', 'plus-circle']" color="grey" class="mx-1"></font-awesome-icon> icon below to add a first nameserver to this group.</p>
                    </v-card-text>
                    <v-simple-table dense>
                        <template #default>
                            <thead>
                                <tr>
                                    <th>
                                        Nameserver
                                        <v-tooltip bottom>
                                            <template #activator="{ on, attrs }">
                                                <v-btn icon @click="showAddNameserverForm = true" v-bind="attrs" v-on="on">
                                                    <font-awesome-icon :icon="['fas', 'plus-circle']" color="green"></font-awesome-icon>
                                                </v-btn>
                                            </template>
                                            Add nameserver
                                        </v-tooltip>
                                    </th>
                                    <th>IP Address List</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(nameserver, nameserverIdx) in nameserverList" :key="nameserverIdx" class="clickable-row" @click.stop="selectNameserverIdx(nameserverIdx)" :class="{ highlight: nameserverIdx === selectedNameserverIdx }">
                                    <td>
                                        <template v-if="nameserverIdx === selectedNameserverIdx">
                                            <v-row no-gutters>
                                            <EditableText :value="nameserver.fqdn" @input="editNameserverFqdn" dense/>
                                            <v-tooltip bottom>
                                                <template #activator="{ on, attrs }">
                                                    <v-btn icon @click="removeNameserverIdx(nameserverIdx)" v-bind="attrs" v-on="on">
                                                        <font-awesome-icon :icon="['fas', 'minus-circle']" color="red"></font-awesome-icon>
                                                    </v-btn>
                                                </template>
                                                Remove nameserver
                                            </v-tooltip>
                                            </v-row>
                                        </template>
                                        <template v-else>
                                            {{ nameserver.fqdn }}
                                        </template>
                                    </td>
                                    <td class="pa-2">
                                        <v-chip small v-for="(ipaddr, ipaddrIdx) in nameserver.ipaddr_list" :key="ipaddrIdx" class="mx-1">
                                            {{ ipaddr }}
                                            <v-avatar right v-if="nameserverIdx === selectedNameserverIdx" @click="removeNameserverIpaddr(ipaddr)">
                                                <font-awesome-icon :icon="['fas', 'minus-circle']" color="red"></font-awesome-icon>
                                            </v-avatar>
                                        </v-chip>
                                        <template v-if="nameserverIdx === selectedNameserverIdx && !showAddNameserverIpaddrForm">
                                        <v-tooltip bottom>
                                            <template #activator="{ on, attrs }">
                                                <v-btn icon @click="showAddNameserverIpaddrForm = true" v-bind="attrs" v-on="on">
                                                    <font-awesome-icon :icon="['fas', 'plus-circle']" color="green"></font-awesome-icon>
                                                </v-btn>
                                            </template>
                                            Add IP address
                                        </v-tooltip>
                                        </template>
                                        <template v-if="nameserverIdx === selectedNameserverIdx && showAddNameserverIpaddrForm">
                                                <v-form @submit.prevent="addNameserverIpaddr" @keyup.enter.prevent="addNameserverIpaddr" class="my-2">
                                            <v-row no-gutters>
                                                <v-text-field label="IP address" v-model="newNameserverIpaddr" ref="newNameserverIpaddrInput" dense></v-text-field>
                                                <v-btn icon :color="primaryColor" @click="addNameserverIpaddr">
                                                    <font-awesome-icon :icon="['fas', 'check']"></font-awesome-icon>
                                                </v-btn>
                                                <v-btn icon color="grey darken-2" @click="showAddNameserverIpaddrForm = false">
                                                    <font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon>
                                                </v-btn>
                                            </v-row>
                                                </v-form>
                                        </template>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-card-text v-if="showAddNameserverForm">
                    <v-form @submit.prevent="submitAddNameserverForm" @keyup.enter.prevent="submitAddNameserverForm">
                        <v-text-field v-model="newNameserverFqdn" ref="newNameserverFqdnInput" label="FQDN" hint="Fully qualified domain name, like ns1.example.com" dense></v-text-field>
                        <v-btn :style="primaryButtonStyle" @click="submitAddNameserverForm">Add</v-btn>
                        <v-btn color="grey--text text--darken-2" class="ml-2" @click="showAddNameserverForm = false">Cancel</v-btn>
                    </v-form>
                    </v-card-text>
                </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style>
tr.clickable-row:hover {
    cursor: pointer;
}
tr.highlight {
    background-color: #FFF9C4; /* yellow lighten-4 */
}
tr.highlight:hover {
    background-color: #FFF59D !important; /* yellow lighten-3 */
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import EditableText from '@/components/EditableText.vue';

export default {
    components: {
        EditableText,
    },
    data: () => ({
        nameserverGroup: null,
        // nameserverList: [
        //     { fqdn: 'ns1.libertydns.test', ipaddr: '1.2.3.4' },
        //     { fqdn: 'ns2.libertydns.test', ipaddr: '5.6.7.8' },
        // ],
        selectedNameserverIdx: null,
        submitTimestamp: null,
        showAddNameserverForm: false,
        showAddNameserverIpaddrForm: false,
        newNameserverFqdn: null,
        newNameserverIpaddr: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            cardTitleBarStyle: 'cardTitleBarStyle',
            cardTitleBarTextStyle: 'cardTitleBarTextStyle',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
        nameserverList() {
            return this.nameserverGroup?.content?.nameserver_list ?? []; // each item like { fqdn, ipaddr_list: [] }  for example  { fqdn: 'ns1.libertydns.io', ipaddr_list: ['1.2.3.4', '5.6.7.8' ] }
        },
    },
    watch: {
        focus() {
            if (this.showAddNameserverForm) {
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('newNameserverFqdnInput'); }, 1);
                });
            }
        },
        showAddNameserverForm(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.newNameserverFqdn = '';
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('newNameserverFqdnInput'); }, 1);
                });
            }
        },
        showAddNameserverIpaddrForm(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.newNameserverIpaddr = '';
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('newNameserverIpaddrInput'); }, 1);
                });
            }
        },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        async loadNameserverGroup() {
            try {
                this.$store.commit('loading', { loadNameserverGroup: true });
                const response = await this.$client.main().nameserverGroup.get({ id: this.$route.query.id });
                this.nameserverGroup = response.item;
            } catch (err) {
                console.error('failed to load nameserver group', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load nameserver group' });
            } finally {
                this.$store.commit('loading', { loadNameserverGroup: false });
                this.isViewReady = true;
            }
        },
        async editNameserverGroup(edit) {
            try {
                this.$store.commit('loading', { editNameserverGroup: true });
                const response = await this.$client.main().nameserverGroup.edit({ id: this.$route.query.id }, edit);
                if (response?.status?.isEdited) {
                    // this.$bus.$emit('snackbar', { type: 'success', headline: 'Saved changes' });
                    return true;
                }
                return false;
            } catch (err) {
                console.error('failed to edit nameserver group', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit nameserver group' });
                return false;
            } finally {
                this.$store.commit('loading', { editNameserverGroup: false });
                this.isViewReady = true;
            }
        },
        async saveNameserverGroupLabel(value) {
            const isEdited = await this.editNameserverGroup({ label: value });
            if (isEdited) {
                this.$set(this.nameserverGroup, 'label', value);
            }
        },
        async saveNameserverGroupContent(value) {
            const isEdited = await this.editNameserverGroup({ content: value });
            if (isEdited) {
                this.$set(this.nameserverGroup, 'content', value);
            }
            return isEdited;
        },
        async submitAddNameserverForm() {
            if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitTimestamp = Date.now();
            const list = this.nameserverGroup?.content?.nameserver_list ?? [];
            list.push({ fqdn: this.newNameserverFqdn, ipaddr_list: [] });
            const content = this.nameserverGroup?.content ?? {};
            content.nameserver_list = list;
            const isEdited = await this.saveNameserverGroupContent(content);
            if (isEdited) {
                this.showAddNameserverForm = false;
                this.selectNameserverIdx(list.length - 1);
            }
        },
        selectNameserverIdx(idx) {
            if (idx !== this.selectedNameserverIdx) {
                this.showAddNameserverIpaddrForm = false;
            }
            this.selectedNameserverIdx = idx;
            this.showAddNameserverForm = false;
        },
        async addNameserverIpaddr() {
            if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitTimestamp = Date.now();
            const list = this.nameserverGroup?.content?.nameserver_list ?? [];
            if (this.selectedNameserverIdx < 0 || this.selectedNameserverIdx >= list.length) {
                console.error('addNameserverIpaddr: invalid selected nameserver index');
                return;
            }
            const nameserver = list[this.selectedNameserverIdx];
            nameserver.ipaddr_list ??= [];
            nameserver.ipaddr_list.push(this.newNameserverIpaddr);
            list[this.selectedNameserverIdx] = nameserver;
            const content = this.nameserverGroup?.content ?? {};
            content.nameserver_list = list;
            const isEdited = await this.saveNameserverGroupContent(content);
            if (isEdited) {
                this.showAddNameserverIpaddrForm = false;
            }
        },
        async removeNameserverIdx(idx) {
            const list = this.nameserverGroup?.content?.nameserver_list ?? [];
            list.splice(idx, 1);
            const content = this.nameserverGroup?.content ?? {};
            content.nameserver_list = list;
            await this.saveNameserverGroupContent(content);
        },
        async removeNameserverIpaddr(ipaddr) {
            const list = this.nameserverGroup?.content?.nameserver_list ?? [];
            if (this.selectedNameserverIdx < 0 || this.selectedNameserverIdx >= list.length) {
                console.error('removeNameserverIpaddr: invalid selected nameserver index');
                return;
            }
            const nameserver = list[this.selectedNameserverIdx];
            nameserver.ipaddr_list ??= [];
            const idx = nameserver.ipaddr_list.findIndex((item) => item === ipaddr);
            if (idx === -1) {
                console.error('removeNameserverIpaddr: invalid selected ipaddr');
                return;
            }
            nameserver.ipaddr_list.splice(idx, 1);
            list[this.selectedNameserverIdx] = nameserver;
            const content = this.nameserverGroup?.content ?? {};
            content.nameserver_list = list;
            await this.saveNameserverGroupContent(content);
        },
        onClickOutside() {
            this.selectedNameserverIdx = null;
        },
        async editNameserverFqdn(value) {
            const list = this.nameserverGroup?.content?.nameserver_list ?? [];
            if (this.selectedNameserverIdx < 0 || this.selectedNameserverIdx >= list.length) {
                console.error('editNameserverFqdn: invalid selected nameserver index');
                return;
            }
            const nameserver = list[this.selectedNameserverIdx];
            nameserver.fqdn = value;
            list[this.selectedNameserverIdx] = nameserver;
            const content = this.nameserverGroup?.content ?? {};
            content.nameserver_list = list;
            await this.saveNameserverGroupContent(content);
        },
        redirectToDeleteNameserverGroup() {
            this.$router.push({ name: 'system-delete-nameserver-group', query: { id: this.$route.query.id, afterDeleteGoBack: 2 } });
        },
    },
    mounted() {
        this.loadNameserverGroup();
    },
};
</script>
