<template>
    <v-form @submit.prevent="submit" @keyup.enter.prevent="submit">
        <template v-if="!editMode">
            <slot name="view"><span v-if="label">{{ label }}: </span>{{ value }}</slot>
            <slot name="button">
                <v-btn icon :color="primaryColor" @click="editMode = true">
                    <font-awesome-icon :icon="['fas', 'pencil-alt']"></font-awesome-icon>
                </v-btn>
            </slot>
        </template>
        <template v-if="editMode">
            <v-row no-gutters>
                <slot name="input">
                    <v-text-field v-model="editableText" :label="label" :hint="hint" :dense="dense" ref="editableTextInput"></v-text-field>
                </slot>
                <slot name="submit">
                    <v-btn icon :color="primaryColor" @click="submit">
                        <font-awesome-icon :icon="['fas', 'check']"></font-awesome-icon>
                    </v-btn>
                    <v-btn icon color="grey darken-2" @click="cancel">
                        <font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon>
                    </v-btn>
                </slot>
            </v-row>
        </template>
    </v-form>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: ['value', 'dense', 'label', 'hint'],
    data: () => ({
        editMode: false,
        editableText: null,
        submitFormTimestamp: null,
    }),
    computed: {
        ...mapGetters({
            primaryColor: 'primaryColor',
        }),
    },
    watch: {
        editMode(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.editableText = this.value;
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('editableTextInput'); }, 1);
                });
            }
        },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        submit() {
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitFormTimestamp = Date.now();
            this.$emit('input', this.editableText);
            this.cancel();
        },
        cancel() {
            this.editMode = false;
            this.editableText = null;
        },
    },
};
</script>
